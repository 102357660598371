import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import * as XLSX from 'xlsx';
export default function InfinityReport() {
    const [data, setData] = useState([]);
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState("00000000-0000-0000-0000-000000000000");
    const [total, setTotal] = useState(0);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [status, setStatus] = useState('ALL');
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const handleInputChange = (e) => {
        setClientId(e.target.value)
    }
    const GetClients = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLCLIENT, { ...headerconfig })
            .then((response) => {
                setClients(response.data.data);
            });
    };
    const GetRequests = () => {
        setData([]);
        setTotal(0);
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETINFINITYREPORT + "?ClientId=" + clientId + "&Status=" + status + "&fromDate=" + moment(fromDate).format('YYYY-MM-DD') + "&toDate=" + moment(toDate).format('YYYY-MM-DD') + "", { ...headerconfig })
            .then((response) => {
                setData(response.data.data);
                setTotal(response.data.data.reduce((a, v) => a = a + v.quantity, 0))
            });
    };
    const selectToDate = (nextdate) => {
        var todate = new Date();
        if (nextdate === undefined) {
            todate = addDays(fromDate)
        }
        else {
            todate = nextdate;
        }
        if (todate < fromDate) {
            alert("To Date should be greater than are equal to From Date")
            addDays(fromDate);
        }
        else {
            setFromDate(fromDate);
            setToDate(todate);
        }
    };
    const exportToExcel = () => {
        var fileName = "Report-" + moment(fromDate).format('DDMMYYYY') + "-" + moment(toDate).format('DDMMYYYY');
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Convert the JSON data to a worksheet
        const ws = XLSX.utils.json_to_sheet(data);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // Save the workbook as an Excel file
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };
    const addDays = (cindate) => {
        if (cindate === undefined) {
            cindate = new Date();
        }
        const newDate = new Date(cindate.getTime());
        if (newDate > toDate) {
            newDate.setDate(cindate.getDate() + 1);
            setToDate(newDate);
        }
        else {
            setToDate(toDate)
        }
        setFromDate(cindate);
        return newDate;
    };
    useEffect(() => {
        GetRequests();
        GetClients();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Reports</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row g-3">
                                            <div class="col-lg-3">
                                                <label for="nameInput" class="form-label">FromDate</label>
                                                <div className="input-group">
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        options={{
                                                            dateFormat: "d-m-Y"
                                                        }}
                                                        value={fromDate}
                                                        onChange={(selectedDates) => {
                                                            addDays(selectedDates[0])
                                                        }}
                                                        name="fromDate"
                                                    />
                                                    <div className="input-group-text bg-primary border-primary text-white">
                                                        <i className="ri-calendar-2-line" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <label class="form-label">ToDate</label>
                                                <div className="input-group">
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        options={{
                                                            dateFormat: "d-m-Y"
                                                        }}
                                                        value={toDate}
                                                        onChange={(selectedDates) => {
                                                            selectToDate(selectedDates[0])
                                                        }}
                                                        name="toDate"
                                                    />
                                                    <div className="input-group-text bg-primary border-primary text-white">
                                                        <i className="ri-calendar-2-line" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-2 col-lg-4">
                                                <label className="form-label">Request Status</label>
                                                <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                                                    <option value="ALL">All</option>
                                                    <option value="NEW">NEW</option>
                                                    <option value="COLLECTOR ASSIGNED">COLLECTOR ASSIGNED</option>
                                                    <option value="COLLECTED">COLLECTED</option>
                                                    <option value="DELIVERED">DELIVERED</option>
                                                    <option value="RECYCLED">RECYCLED</option>
                                                </select>
                                            </div>
                                            <div className="col-xxl-2 col-lg-4">
                                                <label className="form-label">Client</label>
                                                <select name="clientId" value={clientId} onChange={e => setClientId(e.target.value)} className="form-select">
                                                    <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                    {clients.map(rt =>
                                                        <option value={rt.clientId}>{rt.primaryContact}</option>
                                                    )}
                                                </select>
                                            </div>
                                            {/*end col*/}
                                            <div className="col-lg-2">
                                                <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                    <button onClick={GetRequests} type="button" className="btn btn-success">Search</button>

                                                    <button onClick={exportToExcel} type="button" className="btn btn-primary">Download</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end row*/}
                                    </div>
                                </div>
                                <div className="card card-height-100">
                                    <div className="card-header align-items-right d-flex">
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Client </th>
                                                        <th>Requested Date</th>
                                                        <th>Status</th>
                                                        <th style={{alignContent:"center"}}>Items Sent</th>
                                                        <th style={{alignContent:"center"}}>Recycled</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {data.length > 0 && data.map((gt, index) =>
                                                        <tr key={gt.requestId}>
                                                            <td>{index + 1}</td>
                                                            <td className="id">{gt.clientName}</td>
                                                            <td className="id">{moment(moment.utc(gt.pickupRequestedDate).toDate()).format('MM/DD/YYYY')}</td>
                                                            {gt.status === "NEW" ?
                                                                <td className="id"><span className="badge text-bg-primary">{gt.status}</span></td>
                                                                : gt.status === "DELIVERED" ?
                                                                    <td className="id"><span className="badge text-bg-success">{gt.status}</span></td>
                                                                    : gt.status === "RECYCLED" ?
                                                                        <td className="id"><span className="badge text-bg-warning">{gt.status}</span></td>
                                                                        :
                                                                        <td className="id"><span className="badge text-bg-danger">{gt.status}</span></td>
                                                            }
                                                            <td>
                                                            {/* Nested table for Pickup Request Items */}
                                                            {gt.pickupRequestItems && gt.pickupRequestItems.length > 0 ? (
                                                              <table border="1" cellPadding="5" style={{ width: "100%" }}>
                                                                <thead>
                                                                  <tr>
                                                                    <th>Item</th>
                                                                    <th>Pallets</th>                                                                   
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {gt.pickupRequestItems.map((item) => (
                                                                    <tr key={item.pickupRequestItemId}>
                                                                      <td>{item.itemName}</td>
                                                                      <td>{item.quantity} {item.weight}</td>                                                                      
                                                                    </tr>
                                                                  ))}
                                                                </tbody>
                                                              </table>
                                                            ) : (
                                                              <p>Not yet recycled</p>
                                                            )}
                                                          </td>
                                                          <td>
                                                            {/* Nested table for Pickup Request Items */}
                                                            {gt.pickupRecycleItems && gt.pickupRecycleItems.length > 0 ? (
                                                              <table border="1" cellPadding="5" style={{ width: "100%" }}>
                                                                <thead>
                                                                  <tr>
                                                                    <th>Item</th>
                                                                    <th>Pallets</th>                                                                   
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {gt.pickupRecycleItems.map((item) => (
                                                                    <tr key={item.pickupRequestItemId}>
                                                                      <td>{item.itemName}</td>
                                                                      <td>{item.quantity} {item.weight}</td>                                                                      
                                                                    </tr>
                                                                  ))}
                                                                </tbody>
                                                              </table>
                                                            ) : (
                                                              <p>NOT YET RECYCLED</p>
                                                            )}
                                                          </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}